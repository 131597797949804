<template>
  <div class="home">
    <div class="home__head">
      <div class="home__head-left">
        <img src="@/assets/image/logo.png" alt="" />
        <span>全球天气开放平台</span>
      </div>
      <div class="home__head-right">
        <span @click="toHome">首页</span>
        <span @click="toSever">开发文档</span>
        <span @click="toPay">购买</span>
        <span @click="toControl">控制台</span>
        <div
          v-if="!$store.state.account.info.name"
          class="home__head-right-login"
          @click="toLogin"
        >
          登录
        </div>
        <div v-else class="home__head-right-logout">
          <div class="home__head-right-action">
            <div class="home__head-right-action-text" @click="logout">退出登录</div>
          </div>
          {{ $store.state.account.info.name }}，欢迎您
        </div>
      </div>
    </div>
    <div class="home__content">
      <span class="home__content-text-normal">不仅仅是天气预报</span>
      <span class="home__content-text-big">更是开放数据平台</span>
      <div class="home__content-text-small">
        全球天气致力于向社会开放气象资料数据共享平台，向有需要更高需求的公众和专业机构提供均等使用的气象数据
      </div>
      <div class="home__content-btn" @click="toPay">
        <span>去了解</span>
        <div class="home__content-btn-right">
          <img src="@/assets/image/arrow.png" alt="" />
        </div>
      </div>
    </div>
    <div class="home__middle">
      <div class="home__middle-title">全球天气 气盖全球</div>
      <span>我们的气象数据覆盖全球</span>
      <span>在这个蓝色的星球上，每27公里就有一个数据监测点</span>
      <span>想查哪里查哪里</span>
    </div>
    <div class="home__middle mt-1150">
      <div class="home__middle-title">全球天气 时刻分享</div>
      <span>全球天气为各行各业的用户提供有价值的全球气象数据集，</span>
      <span>覆盖航空航天、农业、海洋、公共交通、环保、物流、旅游、金融、零售、</span>
      <span>时尚等多场景，帮助用户发掘新洞见，</span>
      <span>为您在行业中更上一层楼助力</span>
    </div>
    <div class="home__middle mt-851">
      <div class="home__middle-title">全球天气 包罗万象</div>
      <span>我们不仅仅提供大众化的权威天气资料，</span>
      <span>更提供“小众冷门”气象数据。天气，气温，风力，湿度，日出，日落紫外线强度</span>
      <span>空气质量...别家有的我们也有；浪高，潮汐，鱼情，涌浪数据...</span>
      <span>别处查不到的我们也有直戳户外行业细微点，</span>
      <span>满足精细化，专业化的数据要求</span>
    </div>
    <div class="home__footer">
      <div class="home__footer-content">
        <div class="home__footer-content-left">
          <div class="home__footer-content-left-top">
            <div class="home__footer-content-left-top-img">
              <img src="@/assets/image/logo.png" alt="" />
            </div>
            <span>全球天气开发平台</span>
          </div>
          <span class="home__footer-content-left-text">最权威的气象数据提供给您！</span>
        </div>
        <div class="home__footer-content-right">
          <div class="home__footer-content-right-box">
            <div class="home__footer-content-right-title">开发文档</div>
            <span class="cursor-pointer" @click="toPay">API开发文档</span>
            <!-- <span class="cursor-pointer">IOS开发文档</span>
            <span class="cursor-pointer">Adnroid 开发文档</span> -->
          </div>
          <div class="home__footer-content-right-box mr-61">
            <div class="home__footer-content-right-title">相关链接</div>
            <span class="cursor-pointer" @click="toYuHuo">渔获</span>
            <!-- <span class="cursor-pointer">搜罗百科</span> -->
            <span class="cursor-pointer" @click="toChaoXi">全球潮汐</span>
            <span class="cursor-pointer" @click="toTianQi">全球天气</span>
          </div>
          <div class="home__footer-content-right-content">
            <div class="home__footer-content-right-title">联系我们</div>
            <div class="home__footer-content-right-content-item mt-26">
              <img
                class="home__footer-content-right-content-item-icon"
                src="@/assets/image/phone-icon.png"
                alt=""
              />
              <span>+86(051066-888-275)</span>
            </div>
            <div class="home__footer-content-right-content-item mt-38">
              <img
                src="@/assets/image/email-icon.jpg"
                class="home__footer-content-right-content-item-icon_2"
                alt=""
              />
              <span>support@solot.com</span>
            </div>
            <!-- <div class="home__footer-content-right-content-icon mt-35">
              <img src="@/assets/image/zhihu-icon.png" alt="" />
              <img src="@/assets/image/toutiao-icon.png" alt="" />
              <img src="@/assets/image/face-icon.png" alt="" />
              <img src="@/assets/image/tuite-icon.png" alt="" />
              <img src="@/assets/image/wechat-icon.png" alt="" />
              <img src="@/assets/image/weibo-icon.png" alt="" />
            </div> -->
          </div>
          <div class="home__footer-content-right-last">
            <div class="home__footer-content-right-last-box">
              <div class="home__footer-content-right-title">关注我们</div>
              <img
                class="home__footer-content-right-last-code"
                src="@/assets/image/qr_code.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="home__footer-bottom">
        <div class="home__footer-bottom-left">
          <div class="home__footer-bottom-content">
            <span class="mr-41">Copyright © 2020 Solot Inc. 保留所有权利</span>
            <span class="cursor-pointer">服务条款</span>
            <div class="home__footer-bottom-left-line" />
            <span class="cursor-pointer">隐私政策</span>
            <div class="home__footer-bottom-left-line" />
            <span class="cursor-pointer">法律信息</span>
            <div class="home__footer-bottom-left-line" />
            <span class="cursor-pointer">网站地图</span>
          </div>
          <div class="flex mt-17">
            <span class="cursor-pointer text-hover" @click="toBeian">
              苏ICP备15046085号-5
            </span>
            <span class="flex ml-20">
              <img src="@/assets/image/gongan.png" alt="" />
              <span class="cursor-pointer text-hover ml-2" @click="toWangan">
                苏公网安备32021402002237
              </span>
            </span>
          </div>
        </div>
        <!-- <div class="home__footer-bottom-btn cursor-pointer">简体中文</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import util from "@/libs/util.js";
export default {
  name: "Home",
  data() {
    return {
      util,
      code: this.$route.query.code,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const token = util.cookies.get("token");
      if (this.code && !token) {
        this.$store.dispatch("account/getInfo", { code: this.code });
      }
    },
    toHome() {
      this.$router.push("/");
    },
    toSever() {
      this.$router.push("/");
    },
    toControl() {
      const token = util.cookies.get("token");
      if (token) {
        window.open(process.env.VUE_APP_CONTROL_URL, "_blank");
      } else {
        window.open(
          `${process.env.VUE_APP_DOMAIN_URL}/login?client_id=${process.env.VUE_APP_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_CONTROL_URL}`,
          "_blank"
        );
      }
    },
    toPay() {
      window.open("https://market.cloud.tencent.com/stores/1251443620", "_blank");
    },
    toLogin() {
      window.location.href = `${process.env.VUE_APP_DOMAIN_URL}/login?client_id=${process.env.VUE_APP_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_REDIRECT_URI}`;
    },
    logout() {
      this.$store.dispatch("account/logout");
    },
    // 鱼获
    toYuHuo() {
      window.open("https://www.catches.com/index", "_blank");
    },
    // 全球潮汐
    toChaoXi() {
      window.open("https://www.solot.com.cn/tide/zh/", "_blank");
    },
    // 全球天气
    toTianQi() {
      window.open("https://www.solot.com.cn/weather/", "_blank");
    },
    toBeian() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
    },
    toWangan() {
      window.open("http://www.beian.gov.cn/", "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  position: relative;
  background-color: #fff;
  min-width: 1240px;
  background-image: url("~@/assets/image/bg_1.png"), url("~@/assets/image/bg_2.png"),
    url("~@/assets/image/bg_3.png"), url("~@/assets/image/bg_4.png");
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: 1500px 1409px, 1425px 949px, 695px 550px, 1920px 1268px;
  background-position: left 420px top, left 1494px, center 3040px, left bottom 370px;
  display: flex;
  flex-direction: column;
  &__head {
    margin: 24px auto;
    width: 1209px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-left {
      display: flex;
      align-items: center;
      img {
        width: 32px;
        height: 33px;
        margin-right: 24px;
      }
      span {
        font-family: PingFangSC-Semibold;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #242c58;
      }
    }
    &-right {
      display: flex;
      align-items: center;
      span {
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #f9fbff;
        margin-right: 47px;
        cursor: pointer;
      }
      &-login {
        width: 85px;
        height: 33px;
        line-height: 33px;
        border-radius: 17px;
        border: solid 1px #f9faff;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        text-align: center;
        color: #f9fbff;
        cursor: pointer;
      }
      &-logout {
        position: relative;
        line-height: 33px;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        text-align: center;
        color: #f9fbff;
        cursor: pointer;
      }
      &-logout:hover &-action {
        transition: all 0.1s ease-in-out;
        height: 60px;
        opacity: 1;
      }
      &-action {
        transition: all 0.1s ease-in-out;
        position: absolute;
        top: 33px;
        box-sizing: border-box;
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        opacity: 0;
        border-radius: 6px;
        background: #fff;
        color: #333333;
        &-text {
          width: 100%;
          background: #fff;
        }
        &-text:hover {
          transition: all 0.3s ease-in-out;
          color: rgba(89, 63, 251, 1);
          background-color: rgba(89, 63, 251, 0.3);
        }
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    margin-top: 258px;
    margin-left: 8%;
    &-text-normal {
      font-family: PingFangSC-Light;
      font-size: 48px;
      line-height: 48px;
      color: #242c58;
    }
    &-text-big {
      margin-top: 39px;
      font-family: PingFangSC-Light;
      font-size: 72px;
      line-height: 72px;
      color: #242c58;
    }
    &-text-small {
      margin-top: 72px;
      width: 550px;
      font-family: PingFangSC-Light;
      font-size: 24px;
      line-height: 1.5;
      color: #242c58;
    }
    &-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      margin-top: 76px;
      padding: 0 11px 0 45px;
      width: 207px;
      height: 65px;
      background-image: linear-gradient(86deg, #514ab8 0%, #6759ca 100%);
      box-shadow: 0px 22px 62px 0px rgba(89, 63, 251, 0.26);
      border-radius: 33px;
      cursor: pointer;
      span {
        font-family: PingFangSC-Regular;
        font-size: 24px;
        color: #ffffff;
      }
      &-right {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 49px;
        height: 49px;
        background-color: #393497;
        border-radius: 50%;
        img {
          width: 27px;
          height: 14px;
        }
      }
    }
  }
  &__middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 380px auto 0;
    span {
      font-family: PingFangSC-Light;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #242c58;
      line-height: 2;
    }
    &-title {
      font-family: PingFangSC-Light;
      font-size: 48px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #242c58;
      margin-bottom: 63px;
    }
  }
  &__footer {
    margin: 1292px auto 54px;
    width: 1209px;
    &-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      border-bottom: 1px solid #ebe9e9;
      &-left-top {
        display: flex;
        align-items: center;
        &-img {
          box-sizing: border-box;
          width: 39px;
          height: 39px;
          margin-right: 10px;
          padding: 4px;
          background-color: #000;
          border-radius: 12px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        span {
          font-family: PingFangSC-Semibold;
          font-size: 18px;
          line-height: 18px;
          color: #333333;
        }
      }
      &-left-text {
        font-family: PingFangSC-Light;
        font-size: 16px;
        line-height: 61px;
        color: #333333;
      }
      &-right {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        margin-top: 10px;
        margin-bottom: 20px;
        &-box {
          display: flex;
          flex-direction: column;
          margin-right: 42px;
          span {
            font-family: PingFangSC-Regular;
            font-size: 14px;
            line-height: 14px;
            color: #333333;
            margin-top: 28px;
          }
        }
        &-title {
          font-family: PingFangSC-Semibold;
          font-size: 16px;
          line-height: 14px;
          letter-spacing: 0px;
          color: #333333;
          margin-top: 0px;
        }
        &-content {
          margin-right: 51px;
          &-item {
            display: flex;
            align-items: center;
            &-icon {
              width: 17px;
              height: 21px;
              margin-right: 15px;
            }
            &-icon_2 {
              width: 21px;
              height: 15px;
              margin-right: 12px;
            }
            span {
              font-size: 14px;
              line-height: 14px;
              letter-spacing: 0px;
              color: #333333;
            }
          }
          &-icon {
            margin-top: 35px;
            img {
              width: 25px;
              height: 25px;
              margin-right: 20px;
            }
            img:nth-of-type(2) {
              width: 33px;
              height: 22px;
            }
            img:nth-of-type(3) {
              width: 23px;
              height: 26px;
            }
            img:nth-of-type(4) {
              width: 26px;
              height: 25px;
            }
            img:nth-of-type(5) {
              width: 24px;
              height: 24px;
            }
            img:nth-of-type(6) {
              width: 24px;
              height: 24px;
            }
          }
        }
        &-last {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-end;
          &-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 142px;
          }
          &-code {
            margin-top: 18px;
            width: 142px;
            height: 142px;
          }
        }
      }
    }
    &-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      &-left {
        display: flex;
        flex-direction: column;
        span {
          font-family: PingFangSC-Regular;
          font-size: 14px;
          line-height: 14px;
          color: #a0a0a0;
        }

        &-line {
          width: 1px;
          height: 13px;
          background-color: #d4d4d4;
          margin: 0 22px;
        }
      }
      &-content {
        display: flex;
        align-items: center;
      }
      &-btn {
        width: 90px;
        height: 40px;
        background-color: #5a50bf;
        box-shadow: 0px 8px 21px 0px rgba(57, 41, 202, 0.3);
        border-radius: 20px;
        line-height: 40px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
      }
    }
  }
  .mt-1150 {
    margin-top: 1142px;
  }
  .mt-851 {
    margin-top: 851px;
  }
  .mt-26 {
    margin-top: 26px;
  }
  .mt-38 {
    margin-top: 38px;
  }
  .mt-35 {
    margin-top: 35px;
  }
  .mr-61 {
    margin-right: 61px;
  }
  .mr-41 {
    margin-right: 41px;
  }
  .mt-17 {
    margin-top: 17px;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .ml-20 {
    margin-left: 20px;
  }
  .ml-2 {
    margin-left: 2px;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .text-hover {
    color: #a0a0a0;
    text-decoration: none;
  }
  .text-hover:hover {
    color: dodgerblue;
  }
}
</style>
